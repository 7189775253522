import qs from 'qs';
import apiClient from '@/services/skinsPaymentApi/apiClient';

const APP_ASSETS_BY_ID = {
    730: {
        icon: require('@/assets/images/icon-counter-strike.png'),
        logo: require('@/assets/images/logo-counter-strike.png'),
    },
    570: {
        icon: require('@/assets/images/icon-dota.png'),
        logo: require('@/assets/images/logo-dota.png'),
    },
    252490: {
        icon: require('@/assets/images/icon-rust.png'),
        logo: require('@/assets/images/logo-standoff-2.png'),
    },
};

export const application = (email, website, password, passwordConfirmation) =>
    apiClient.post('landing-api/applications', {
        email,
        website,
        password,
        password_confirmation: passwordConfirmation,
    });

export const resendCode = applicationId => apiClient.get(`landing-api/applications/${applicationId}/verify-email`);

export const verifyEmail = (applicationId, code) => apiClient.post(`landing-api/applications/${applicationId}/verify-email`, { code });

export const verifyDomain = applicationId => apiClient.post(`landing-api/applications/${applicationId}/verify-domain`);

export const requestLoginCode = (email, password, recaptchaToken) =>
    apiClient.post('user-cabinet-api/auth/request-login-code', {
        email,
        password,
        'g-recaptcha-response': recaptchaToken,
    });

export const login = (email, password, code) =>
    apiClient.post('user-cabinet-api/auth/login', {
        email,
        password,
        code,
    });

export const requestResetPassword = (email, recaptchaToken) =>
    apiClient.post('user-cabinet-api/auth/password-forgot', {
        email,
        'g-recaptcha-response': recaptchaToken,
    });

export const verifyResetPassword = (email, code, recaptchaToken) =>
    apiClient.post('user-cabinet-api/auth/verify-password-reset-code', {
        email,
        code,
        'g-recaptcha-response': recaptchaToken,
    });

export const resetPassword = (email, code, password, passwordConfirmation) =>
    apiClient.post('user-cabinet-api/auth/password-reset', {
        email,
        code,
        password,
        password_confirmation: passwordConfirmation,
    });

export const changePassword = (password, newPassword, newPasswordConfirmation) =>
    apiClient.post('user-cabinet-api/auth/password-change', {
        password: password,
        password_new: newPassword,
        password_new_confirmation: newPasswordConfirmation,
    });

export const enableTfa = () => apiClient.post('user-cabinet-api/auth/two-factor-authentication-enable');

export const confirmTfa = code => apiClient.post('user-cabinet-api/auth/confirm-two-factor-authentication', { two_factor_confirmation_code: code });

export const recoveryCodes = password => apiClient.post('user-cabinet-api/auth/two-factor-recovery-codes', { password_confirmation: password });

export const getUser = () => apiClient.get('user-cabinet-api/auth/user');

export const changeLocale = locale => apiClient.post('user-cabinet-api/change-locale', { locale });

export const projects = (page = 1) => apiClient.get('user-cabinet-api/projects', { params: { page } });

export const project = id => apiClient.get(`user-cabinet-api/projects/${id}`);

export const projectOrders = (id, page = 1, filter = null) => {
    if (filter && filter.date) {
        filter.from = filter.date[0].toISODate();
        filter.to = filter.date[1].toISODate();

        delete filter.date;
    }

    return apiClient.get(`user-cabinet-api/projects/${id}/deposits`, {
        params: { filter, page },
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices', skipNulls: true }),
    });
};

export const projectWithdraws = (id, page = 1, filter = null) => {
    if (filter && filter.date) {
        filter.from = filter.date[0].toISODate();
        filter.to = filter.date[1].toISODate();

        delete filter.date;
    }

    return apiClient.get(`user-cabinet-api/projects/${id}/withdrawals`, {
        params: { filter, page },
        paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices', skipNulls: true }),
    });
};

export const projectCreate = website => apiClient.post('user-cabinet-api/projects', { website });

export const projectUpdate = (id, data) => apiClient.patch(`user-cabinet-api/projects/${id}`, data);

export const steamApps = () =>
    apiClient.get('payment-api/steam-apps').then(response => {
        response.data = response.data.map(item => ({
            ...item,
            icon: APP_ASSETS_BY_ID[item.id] ? APP_ASSETS_BY_ID[item.id].icon : null,
            logo: APP_ASSETS_BY_ID[item.id] ? APP_ASSETS_BY_ID[item.id].logo : null,
        }));

        return Promise.resolve(response);
    });

export const tradeUrl = (hash, url) => apiClient.patch('payment-api/deposit/tradeUrl', { payment_url_hash: hash, trade_url: url });

export const inventory = (hash, appId) =>
    apiClient.get('payment-api/deposit/inventory', {
        params: {
            deposit_hash: hash,
            steam_app: appId,
        },
    });

export const orderInfo = hash => apiClient.get('payment-api/deposit/info', { params: { deposit_hash: hash } });

export const trade = (hash, appId, items) => apiClient.post('payment-api/trade', { deposit_hash: hash, steam_app: appId, items: items });

export const chartData = (projectId, from, to) => apiClient.get(`user-cabinet-api/projects/${projectId}/chartStats`, { params: { from, to } });

export const postbackAgain = (projectId, orderId) => apiClient.post(`user-cabinet-api/projects/${projectId}/deposits/${orderId}/postbackAgain`);

export const apiDocs = locale => apiClient.get(`api/client/documentation/${locale}/client-api-docs-${locale}.json`);
